.icon-bar {
    position: fixed;
    top: 50%;
    right: 3%;
    transform: translateY(15%);
    z-index: 200;
}

.icon-bar a {
    display: block;
    text-align: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-top: 16px;
    padding: 3px;
    box-shadow: 1px 2px 8px 5px rgb(0 0 0 / 18%);
}

.icon_bar_ChatWithUs {
    position: fixed;
    top: 52%;
    right: 9%;
    transform: translateY(305%);
    padding: 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px 5px rgb(0 0 0 / 20%);
    border-radius: 25px;
    z-index: 200;
}

.icon-bar .imgChatWithUs {
    text-align: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-top: 16px;
    padding: 3px;
}

.icon-bar a:hover {
    background: grey;
    color: #000;
}
