.consultation-bg {
    background: linear-gradient(90deg, rgba(255, 168, 0, 0.3) 0%, rgba(241, 100, 57, 0.3) 76.04%), #F7921E;
    padding: 50px 0px 45px 0px;
}

.consultation__description {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
}

.consultation__call {
    color: #FFFFFF;
    font-weight: 900;
    font-size: 24px;
}

.consultation-btn {
    background-color: #ffffff;
    border: none;
    align-items: center;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    color: #F78D1F !important;
    outline: none;
    border-radius: 14px;
    padding: 10px;
    margin-top: 10px;
}

.consultation-btn:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #F78D1F;
}

.consultation-btn:active {
    outline: none;
    border: none;
}

.consultation-btn:focus {
    outline: none !important;
    box-shadow: none;
}

.header__consultation__text {
    color: #484848;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 173%;
    font-size: 20px;
    text-align: start;
    overflow: hidden;
    margin-bottom: 0rem !important;
}

/* react modal consultaion start */
.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 4px;
    width: 46%;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1050;
}

/* react modal consultaion start */

.footer-hr {
    background: #484848;
    padding: 2px;
}

/* .footer-privacy, .footer-contact{
    list-style: none;
    padding-top: 15px;
} */
/* .footer-privacy li{
    padding-top: 8px;
    color: #484848;
} */
.footer-privacy-section {
    padding-left: 25px;
}

.footer-privacy,
.footer-terms {
    padding-bottom: 8px;
    color: #484848;
}

.footer_social_icon {
    margin-top: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    justify-content: space-between;
    display: flex;
}

.footer-contact {
    display: flex;
    align-items: start;
}

.header_link_footer {
    line-height: 180%;
    color: #fff;
    font-weight: 600;
    padding-bottom: 10px;
}
.ft__desc {
   color: #ffffff;
    font-size: 14px;
    line-height: 173%;
}

.link_footer {
    line-height: 120%;
    text-decoration: none;
    font-size: 15px;
    color: #fff;
}

.link_footer:hover {
    /* color: #484848; */
    color: #ffffff;
}

.contact__foot ul li {
    line-height: 235%;
    padding-left: 15px;
    color: #484848;
}


.footer__membership ul li img {
    width: 220px;
    height: 75px;
    padding: 0px 20px;
}

.contact__foot__bottom ul li {
    line-height: 235%;
    color: #484848;
}

.footer__img img {
    width: 100%;
    height: 120px;
    padding: 0px 35px;
}

.header_contact_us_footer {
    line-height: 180%;
    color: #2A93C0;
}

.contact_us_footer {
    line-height: 180%;
}

.footer_bg {
    background: #FFFFFF;
}

.footer_copyright {
    text-align: center;
    font-size: small;
    background: #F78D1F;
    color: white;
    padding: 10px;

}

.close_btn {
    background: #c9cecb;
    padding: 10px 13px;
    border-radius: 50%;
}

.close_btn:hover {
    background: #c9edde;
}

.bd__img {
    margin-left: 35px;
}

.bd__img img {
    width: 50px;
    height: 30px;
}

.footer__address {
    margin-left: 5px;
}


.partner__carousel__box__card .card {
    background: #FFFFFF;
    box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 2%);
    border-radius: 10px;
}

.partner__carousel__img img{
   width: 120px;
   height: 120px;
}

.partner__carousel__box__card__title {
    color: #000000;
    font-weight: 600;
    margin-top: 5px;
    line-height: 145%;
    text-align: center;
}

.partner__carousel__box__card__disc {
    color: #000000;
    margin-top: 5px;
    margin-bottom: 14px;
    font-size: 15px;
    text-align: center;
    line-height: 145%;
}
.ft__bg__color {
    background: #1f1c1c;
}




@media only screen and (max-width: 768px) {
    .consultation-bg {
        padding: 10px 0px 25px 0px;
    }

    .consultation {
        padding: 0px 65px;
    }

    .consultation-btn {
        font-size: 15px;
    }

    .consultation__call {
        font-weight: 700;
        font-size: 15px;
    }

    .consultation__description {
        font-weight: 500;
        line-height: 170%;
        font-size: 14px;
    }

    /* react modal consultaion start */
    .mymodal {
        width: 90%;
    }

    /* react modal consultaion start */

    .link_footer {
        line-height: 90%;
    }

    .footer-img {
        text-align: center;
    }

    .footer-privacy,
    .footer-terms {
        text-align: center;
        padding-bottom: 0px;
    }

    .footer-privacy-section {
        padding-left: 0px;
    }

    .footer_social_icon {
        margin-top: 7px;
        justify-content: space-evenly;
    }

    .footer-contact-section {
        padding-top: 18px;
    }

    .footer-contact {
        justify-content: center;
    }

    .contact__foot ul li {
        line-height: 235%;
        padding-left: 15px;
        text-align: center;
    }

    .header_link_footer {
        text-align: center;
        padding-bottom: 10px;
    }

    .link_footer {
        text-align: center;
    }

    .contact__foot__bottom ul li {
        line-height: 173%;
        color: #484848;
        text-align: center;
    }

    .footer__img {
        text-align: center;
    }

    .footer__img img {
        width: 100%;
        height: 130px;
    }

    .footer__membership ul li img {
        width: 120px;
        height: 60px;
        padding: 0px 10px;
    }

    .bd__img {
        text-align: center;
        margin-left: 0px;
    }

    .bd__img img {
        width: 50px;
        height: 30px;
    }

}